var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-0"},[_c('v-col',{staticClass:"text-left py-0",attrs:{"cols":"12","md":"9"}},[_c('PageHeader',{directives:[{name:"show",rawName:"v-show",value:(_vm.showExpand),expression:"showExpand"}],staticClass:"pb-0",attrs:{"title":_vm.title}})],1),(
        (_vm.ayudas.length > 0 &&
          ((_vm.ayudaPersonalizada != null &&
            !_vm.ayudaPersonalizada.deshabilitado) ||
            (_vm.ayudaSistema != null && !_vm.ayudaSistema.deshabilitado))) ||
          _vm.isAdministrador ||
          _vm.isSuperUsuario
      )?_c('v-col',{staticClass:"text-right py-0 pr-11",attrs:{"cols":"12","md":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.openAndCloseHelp(), _vm.changeAyudaText(_vm.ayudaTab)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"primary--text pb-1",attrs:{"large":""}},[_vm._v(_vm._s(_vm.helpIcon))])],1)]}}],null,false,3058649793)},[_c('span',[_vm._v(_vm._s(_vm.showHelp ? "Ocultar" : "Mostrar")+" ayuda ")])])],1):_vm._e()],1),_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHelp),expression:"showHelp"}],staticClass:"text-left",attrs:{"cols":"12","sm":"12"}},[_c('v-expand-transition',[_c('v-banner',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHelp),expression:"showHelp"}],class:_vm.bannerClassWithScroll,attrs:{"outlined":"","tag":"v-card","max-height":"30rem","id":"bannerAyuda","rounded":"","right":""}},[_c('v-tabs',{staticClass:"transparentClass pt-0 px-0",attrs:{"id":"ayudasTabs","center-active":"","active-class":"active","hide-slider":"","background-color":"#fafafa","height":"2rem"},on:{"change":function($event){return _vm.changeAyudaText(_vm.ayudaTab)}},model:{value:(_vm.ayudaTab),callback:function ($$v) {_vm.ayudaTab=$$v},expression:"ayudaTab"}},[(
                (_vm.ayudaSistema != null &&
                  _vm.ayudaSistema.deshabilitado == false) ||
                  _vm.isAdministrador ||
                  _vm.isSuperUsuario
              )?_c('v-tab',{attrs:{"href":"#sistema"}},[_vm._v(" Sistema ")]):_vm._e(),(
                (_vm.ayudaPersonalizada != null &&
                  _vm.ayudaPersonalizada.deshabilitado == false) ||
                  _vm.isAdministrador ||
                  _vm.isSuperUsuario
              )?_c('v-tab',{attrs:{"href":"#personalizada"}},[_vm._v(" Personalizada ")]):_vm._e()],1),_c('v-tabs-items',{model:{value:(_vm.ayudaTab),callback:function ($$v) {_vm.ayudaTab=$$v},expression:"ayudaTab"}},[_c('v-tab-item',{attrs:{"value":_vm.ayudaTab == 'sistema' ? 'sistema' : 'personalizada'}},[_c('v-row',{staticClass:"transparentClass"},[_c('v-col',{staticClass:"pb-0 pt-3",attrs:{"cols":"12","md":"6"}}),_c('v-col',{staticClass:"pb-0 pt-3 text-right",attrs:{"cols":"12","md":"6"}},[(_vm.isAdministrador || _vm.isSuperUsuario)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"p-1",attrs:{"small":""},on:{"click":function($event){_vm.ayudaTab == 'sistema'
                            ? _vm.toggleModalTextoAyuda(_vm.ayudaSistema)
                            : _vm.toggleModalTextoAyuda(_vm.ayudaPersonalizada)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,false,1867787789)},[_c('span',[_vm._v(_vm._s(_vm.isAdministrador && _vm.ayudaTab == "sistema" ? "Deshabilitar ayuda" : "Editar texto de ayuda"))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"p-1",attrs:{"small":""},on:{"click":_vm.openAndCloseExpand}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.showExpand ? _vm.collapseIcon : _vm.expandIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.showExpand ? "Contraer ayuda" : "Expandir ayuda"))])])],1)],1),_c('v-card-text',{staticClass:"py-0 px-0 transparentClass"},[_c('div',{staticClass:"pb-0",domProps:{"innerHTML":_vm._s(_vm.text)}})])],1)],1)],1)],1)],1)],1),(_vm.modalTextoAyuda)?_c('v-dialog',{attrs:{"max-width":"70rem","persistent":"","retain-focus":false},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalTextoAyuda()}},model:{value:(_vm.modalTextoAyuda),callback:function ($$v) {_vm.modalTextoAyuda=$$v},expression:"modalTextoAyuda"}},[_c('TextoAyuda',{attrs:{"ayudaObject":_vm.ayudaToEdit,"ayudaTab":_vm.ayudaTab,"optionCode":_vm.optionCode,"isAdministrador":_vm.isAdministrador},on:{"toggleModalTextoAyuda":_vm.toggleModalTextoAyuda,"getAyuda":_vm.getAyudasPantalla}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }