<template>
  <v-container>
    <v-row class="py-0">
      <!-- Título de pantalla -->
      <v-col cols="12" md="9" class="text-left py-0">
        <PageHeader :title="title" class="pb-0" v-show="showExpand" />
      </v-col>

      <!-- Ícono de ayuda -->
      <v-col
        cols="12"
        md="3"
        class="text-right py-0 pr-11"
        v-if="
          (ayudas.length > 0 &&
            ((ayudaPersonalizada != null &&
              !ayudaPersonalizada.deshabilitado) ||
              (ayudaSistema != null && !ayudaSistema.deshabilitado))) ||
            isAdministrador ||
            isSuperUsuario
        "
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="openAndCloseHelp(), changeAyudaText(ayudaTab)"
              v-on="on"
              v-bind="attrs"
              ><v-icon large class="primary--text pb-1">{{ helpIcon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ showHelp ? "Ocultar" : "Mostrar" }} ayuda </span>
        </v-tooltip>
      </v-col>
    </v-row>

    <!-- Texto de ayuda principal -->

    <v-row>
      <v-col cols="12" sm="12" v-show="showHelp" class="text-left">
        <v-expand-transition>
          <v-banner
            outlined
            tag="v-card"
            max-height="30rem"
            id="bannerAyuda"
            rounded
            right
            v-show="showHelp"
            :class="bannerClassWithScroll"
          >
            <!-- Tabs  -->

            <v-tabs
              id="ayudasTabs"
              v-model="ayudaTab"
              center-active
              active-class="active"
              @change="changeAyudaText(ayudaTab)"
              hide-slider
              class="transparentClass pt-0 px-0"
              background-color="#fafafa"
              height="2rem"
            >
              <!-- Sistema -->

              <v-tab
                href="#sistema"
                v-if="
                  (ayudaSistema != null &&
                    ayudaSistema.deshabilitado == false) ||
                    isAdministrador ||
                    isSuperUsuario
                "
              >
                Sistema
              </v-tab>

              <!-- Personalizada -->

              <v-tab
                href="#personalizada"
                v-if="
                  (ayudaPersonalizada != null &&
                    ayudaPersonalizada.deshabilitado == false) ||
                    isAdministrador ||
                    isSuperUsuario
                "
              >
                Personalizada
              </v-tab>
            </v-tabs>

            <!-- Contenido -->

            <v-tabs-items v-model="ayudaTab">
              <v-tab-item
                :value="ayudaTab == 'sistema' ? 'sistema' : 'personalizada'"
              >
                <v-row class="transparentClass">
                  <v-col cols="12" md="6" class="pb-0 pt-3"> </v-col>

                  <!-- Editar y Expandir -->

                  <v-col cols="12" md="6" class="pb-0 pt-3 text-right">
                    <v-tooltip top v-if="isAdministrador || isSuperUsuario">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          @click="
                            ayudaTab == 'sistema'
                              ? toggleModalTextoAyuda(ayudaSistema)
                              : toggleModalTextoAyuda(ayudaPersonalizada)
                          "
                          class="p-1"
                        >
                          {{ editIcon }}
                        </v-icon>
                      </template>
                      <span>{{
                        isAdministrador && ayudaTab == "sistema"
                          ? "Deshabilitar ayuda"
                          : "Editar texto de ayuda"
                      }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          small
                          v-on="on"
                          @click="openAndCloseExpand"
                          class="p-1"
                        >
                          {{ showExpand ? collapseIcon : expandIcon }}
                        </v-icon>
                      </template>
                      <span>{{
                        showExpand ? `Contraer ayuda` : `Expandir ayuda`
                      }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>

                <!-- Texto -->

                <v-card-text class="py-0 px-0 transparentClass">
                  <div class="pb-0" v-html="text"></div>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-banner>
        </v-expand-transition>
      </v-col>
    </v-row>

    <!-- Modal texto ayuda -->

    <v-dialog
      v-if="modalTextoAyuda"
      v-model="modalTextoAyuda"
      max-width="70rem"
      @keydown.esc="toggleModalTextoAyuda()"
      persistent
      :retain-focus="false"
    >
      <!-- :retain-focus="false" fix error que no dejaba crear links(mantenia el focus en el elemento anterior en vez del dialog de Links) -->

      <TextoAyuda
        :ayudaObject="ayudaToEdit"
        :ayudaTab="ayudaTab"
        :optionCode="optionCode"
        :isAdministrador="isAdministrador"
        @toggleModalTextoAyuda="toggleModalTextoAyuda"
        @getAyuda="getAyudasPantalla"
      ></TextoAyuda>
    </v-dialog>
  </v-container>
</template>
<script>
import enums from "@/utils/enums/index.js";

import PageHeader from "@/components/ui/PageHeader";
import { mapActions, mapGetters } from "vuex";
import { mapState } from "vuex";

import TextoAyuda from "@/components/modules/administracion-sistema/TextoAyuda.vue";

export default {
  name: "Ayuda",
  components: { TextoAyuda, PageHeader },
  props: {
    optionCode: {
      Type: String,
      required: false,
      default: ""
    },
    pantallaTitle: { Type: String, required: false, default: "" }
  },
  async created() {
    if (!this.optionCode.length == 0) {
      await this.$store.dispatch(
        "user/updateFrequencyByOptionCode",
        this.optionCode
      );

      this.getAyudasPantalla();
    }
  },
  data() {
    return {
      expandIcon: enums.icons.ARROW_EXPAND,
      collapseIcon: enums.icons.ARROW_COLLAPSE,
      editIcon: enums.icons.EDIT,
      helpIcon: enums.icons.HELP,
      bannerClassWithScroll: "scroll primary--text px-2",
      title: this.pantallaTitle,
      views: [],
      allowedActions: null,
      showHelp: false,
      showExpand: false,
      modalTextoAyuda: false,
      ayudaTab: "sistema",
      ayudas: [],
      ayudaSistema: null,
      ayudaPersonalizada: null,
      isAyudaSistema: true,
      isAdministrador: false,
      isSuperUsuario: false,
      deshabilitado: false,
      text: "",
      ayudaToEdit: null
    };
  },
  beforeDestroy() {
    if (this.ayudaOpened) {

      this.closeHelp();
    }
  },
  computed: {
    ...mapGetters({
      ayudaOpened: "shared/ayudaOpened"
    })
  },
  watch: {
    ayudaOpened(val) {
      if (val == false) {
        this.showHelp = false;
        this.showExpand
          ? [(this.showExpand = false), this.$emit("toggleExpand")]
          : [];
      }
      this.$emit("toggleHelp");
    }
  },
  methods: {
    ...mapActions({
      getAyuda: "AdministracionSistema/getAyuda",
      closeLeftMenu: "shared/closeLeftMenu",
      closeHelp: "shared/closeHelp"
    }),
    whereToPush(item) {
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true
      });
      this.$router.push({
        name: item.path
      });
    },
    changeAyudaText(tab) {
      if (tab == "sistema") {
        this.text = this.ayudaSistema ? this.ayudaSistema.texto : "";
      } else {
        this.text = this.ayudaPersonalizada
          ? this.ayudaPersonalizada.texto
          : "";
      }
    },
    toggleModalTextoAyuda(ayuda) {
      this.ayudaToEdit = ayuda;
      this.modalTextoAyuda = !this.modalTextoAyuda;
    },
    async getAyudasPantalla() {
      const response = await this.getAyuda(this.optionCode);
      this.ayudas = response.ayudas;
      this.isAdministrador = response.administrador;
      this.isSuperUsuario = response.superUsuario;
      if (this.ayudas.length > 0) this.$emit("toggleIcon");
      this.ayudas.forEach(ayuda => {
        if (ayuda.esAyudaSistema === true) {
          this.ayudaSistema = ayuda;
        } else {
          this.ayudaPersonalizada = ayuda;
        }
      });
      this.changeAyudaText(this.ayudaTab);
    },
    openAndCloseExpand() {
      this.showExpand = !this.showExpand;
      this.$emit("toggleExpand");
    },
    openAndCloseHelp() {
      this.showHelp = !this.showHelp;
      if (this.showHelp) {
        this.closeLeftMenu();
      } else {
        this.showExpand
          ? [(this.showExpand = false), this.$emit("toggleExpand")]
          : [];
        this.closeHelp();
      }
    }
  }
};
</script>

<style scoped>
.active {
  color: #f8f9fa !important;
  background-color: #195472 !important;
}
.transparentClass {
  background-color: #fafafa !important;
}
.scroll {
  overflow-y: scroll;
}
#ayudasTabs {
  border-radius: 0.2rem !important;
}
#bannerAyuda .active {
  border-radius: 0.2rem !important;
}
</style>
