<template>
  <v-text-field
    dense
    outlined
    ref="inputRef"
    type="text"
    :value="formattedValue"
    :label="label"
    :filled="filled"
    :disabled="disabled"
    :rules="rules"
    :append-icon="appendIcon"
  />
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";
import rules from "@/utils/helpers/rules";
import { watch } from "@vue/composition-api";

export default {
  name: "CurrencyInput",
  props: {
    value: [Number, String], // Vue 2: value
    options: Object,
    label: String,
    rules: rules,
    appendIcon: "",
    filled: '',
    disabled: ''
  },
  setup(props) {
    const { formattedValue, inputRef, setOptions, setValue } = useCurrencyInput(
      props.options
    );
    watch(
      () => props.value,
      value => {
        // Vue 2: props.value
        setValue(value);
      }
    );

    watch(
      () => props.options,
      options => {
        setOptions(options);
      }
    );
    return { inputRef, formattedValue };
  }
};
</script>
