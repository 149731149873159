<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form1"
          form="form1"
          id="form1"
          @submit.prevent="saveEdit()"
        >
          <v-form
            v-model="searchValid"
            ref="grupo-familiar-filter2"
            id="grupo-familiar-filter2"
            @submit.prevent="filterAfiliados()"
          >
            <v-card class="py-0">
              <v-row class="py-0">
                <v-col cols="4" class="pb-3 mr-0 pr-0">
                  <v-card-title class="primary--text py-0"
                    >Búsqueda de afiliado</v-card-title
                  >
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                  sm="10"
                  align-self="center"
                  class="py-0"
                >
                  <FiltersSelected
                    :filters="filtersApplyed"
                    v-if="!busquedaAfiliado"
                  />
                </v-col>
                <v-col cols="12" md="3" sm="12" class="text-right pb-0  pr-8">
                  <v-btn
                    v-show="grupoSelected"
                    size="20"
                    @click="toggleBusquedaAfiliado()"
                    class="p-1"
                    dense
                    outlined
                    small
                    ><v-icon>{{ busquedaIcon }}</v-icon>
                    {{ toggleBusquedaTooltip }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-expand-transition>
                <v-row v-if="busquedaAfiliado">
                  <v-col cols="12" md="10" sm="12" class="py-0 pl-8">
                    <v-row>
                       <!-- campo documento -->
                      <v-col cols="12" md="4" class="py-0">
                        <v-text-field
                          v-model="afiliadoSearchDocumento"
                          label="N° de documento"
                          dense
                          clearable
                          v-mask="'########'"
                          outlined
                          @change="changeSearchAfi(afiliadoSearchDocumento)"
                        >
                        </v-text-field>
                      </v-col>
                       <!-- campo apellido -->
                      <v-col cols="12" md="4" class="py-0">
                        <v-text-field
                          v-model="afiliadoSearchApellido"
                          label="Apellido/s"
                          dense
                          :rules="[rules.maxLength(afiliadoSearchApellido, 50)]"
                          clearable
                          outlined
                          @change="changeSearchAfi(afiliadoSearchApellido)"
                        >
                        </v-text-field>
                      </v-col>
                       <!-- campo nombre -->
                      <v-col cols="12" md="4" class="py-0">
                        <v-text-field
                          v-model="afiliadoSearchNombre"
                          label="Nombre/s"
                          dense
                          :rules="[rules.maxLength(afiliadoSearchNombre, 50)]"
                          clearable
                          outlined
                          @change="changeSearchAfi(afiliadoSearchNombre)"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="2" class="py-1 text-left">
                    <v-btn
                      color="primary"
                      elevation="2"
                      small
                      type="submit"
                      form="grupo-familiar-filter2"
                    >
                      Buscar
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="showDataTable"
                    cols="12"
                    md="12"
                    sm="12"
                    class="py-0 text-center"
                  >
                    <v-card class="pt-0">
                      <v-data-table
                        class="pt-0"
                        :headers="headers"
                        :items="gruposFamiliaresResult"
                        :loading="loading"
                        dense
                        fixed-header
                        show-select
                        single-select
                        v-model="grupoSelected"
                        @input="afiliadoSelected()"
                      >
                        <template v-slot:[`header.data-table-select`]>
                          <span>Seleccionar</span>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expand-transition>
            </v-card>
          </v-form>
          <v-row class="py-0 pt-7">
            <!-- Concepto -->
            <v-col cols="5" sm="4" md="4" class="py-0">
              <v-autocomplete
                v-model="conceptoSelected"
                :items="itemsConcepto"
                return-object
                label="Concepto"
                item-text="value"
                :rules="rules.required"
                item-value="id"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Importe -->
            <v-col cols="3" class="py-0">
              <currency-input
                label="Importe"
                v-model="importeFijo"
                oulined
                dense="false"
                outlined="false"
                :options="currencyOptions"
                :rules="rules.required"
              >
              </currency-input>
            </v-col>
          </v-row>
          <v-row>
            <!-- Periodo desde -->
            <v-col cols="3" md="3" class="py-0">
              <v-text-field
                dense
                outlined
                clearable
                v-model="periodoDesde"
                label="Período desde"
                hint="Formato AAAAMM"
                persistent-hint
                type="text"
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(periodoDesde, periodoHasta)],
                    rules.periodoYyyyMm
                  )
                "
                v-mask="'######'"
              ></v-text-field>
            </v-col>
            <!-- Periodo hasta -->
            <v-col cols="3" md="3" class="py-0">
              <v-text-field
                dense
                outlined
                clearable
                v-model="periodoHasta"
                label="Período hasta"
                hint="Formato AAAAMM"
                persistent-hint
                type="text"
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(periodoDesde, periodoHasta)],
                    rules.periodoYyyyMm
                  )
                "
                v-mask="'######'"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid || nroGrupo === null"
          form="form1"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import FiltersSelected from "@/components/shared/FiltersSelected";

export default {
  name: "NuevoImporteFijoPorGrupoFliar",
  components: {
    CurrencyInput,
    FiltersSelected
  },
  directives: { mask },
  props: {
    itemImporteFijo: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    formEditTitle: "Editar importe fijo por grupo familiar",
    rules: rules,
    isFormValid: false,
    searchIcon: enums.icons.SEARCH,
    //busqueda afiliado
    afiliadoSearchNombre: null,
    afiliadoSearchApellido: null,
    afiliadoSearchDocumento: null,
    loading: false,
    searchValid: false,
    showDataTable: true,
    filtersApplyed: [],
    busquedaAfiliado: true,
    toggleBusquedaTooltip: "Ocultar filtros",
    busquedaIcon: "mdi-menu-down",
    grupoSelected: [],
    gruposFamiliaresResult: [],
    headers: [
      {
        text: "Afiliado",
        align: "start",
        sortable: true,
        value: "value"
      },
      {
        text: "N° de documento",
        sortable: false,
        value: "doc"
      },
      {
        text: "N° de grupo familiar",
        sortable: false,
        value: "nroGrupo"
      }
    ],
    //campos nuevo/editar
    nroGrupo: null,
    importeFijo: null,
    periodoVigencia: null,
    conceptoSelected: null,
    itemsConcepto: [],
    periodoDesde: null,
    periodoHasta: null,
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: 0.01,
        max: 9999999.99
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true
    }
  }),
  created() {
    this.setSelects();
    if (this.itemImporteFijo != null) {
      this.setTipoCobertura(this.itemImporteFijo);
    } else {
      this.formEditTitle = "Nuevo importe fijo por grupo familiar";
    }
  },
  methods: {
    ...mapActions({
      getConceptosAdicionales: "devengamientos/getConceptosAdicionales",
      saveImportesFijosADevengar: "devengamientos/saveImportesFijosADevengar",
      fetchAfiliadosByParams: "agenteCuenta/fetchAfiliadosByParams",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const getConceptosAdicionales = await this.getConceptosAdicionales();
      this.itemsConcepto = getConceptosAdicionales;
    },
    setTipoCobertura(item) {
      this.nroGrupo = item.benGrId;
      this.conceptoSelected = item.concepto;
      this.importeFijo = item.importeToEdit;
      this.periodoDesde = item.periodoDesde;
      this.periodoHasta = item.periodoHasta;
      this.toggleBusquedaAfiliado();
    },
    async saveEdit() {
      this.isFormValid = false;
      const data = {
        adicionalId: this.itemImporteFijo?.adicionalId,
        benGrId: this.nroGrupo,
        periodoDesde: this.periodoDesde,
        periodoHasta: this.periodoHasta,
        conceptoId: this.conceptoSelected.id,
        importe: this.importeFijo,
        adicionalProcesoModId: null,
        solMovId: null
      };
      try {
        const res = await this.saveImportesFijosADevengar(data);
        if (res.status === 200) {
          this.isFormValid = true;
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.closeModal();
        }
      } catch (error) {
        this.isFormValid = true;
      }
    },
    async filterAfiliados() {
      if (
        (this.afiliadoSearchNombre != null &&
          this.afiliadoSearchNombre != "") ||
        (this.afiliadoSearchDocumento != null &&
          this.afiliadoSearchDocumento != "") ||
        (this.afiliadoSearchApellido != null &&
          this.afiliadoSearchApellido != "")
      ) {
        this.loading = true;
        this.customizeFiltersApplied();
        const data = {
          apellido: this.afiliadoSearchApellido,
          dni: parseInt(this.afiliadoSearchDocumento),
          nombre: this.afiliadoSearchNombre
        };
        try {
          this.showDataTable = true;
          const response = await this.fetchAfiliadosByParams(data);
          this.gruposFamiliaresResult = response;
          this.loading = false;
        } catch {
          this.loading = false;
        }
      } else {
        this.setAlert({
          type: "warning",
          message: "Ingrese al menos un parámetro de búsqueda"
        });
      }
    },
    afiliadoSelected() {
      if (this.grupoSelected.length > 0) {
        this.nroGrupo = this.grupoSelected[0].nroGrupo;
        this.toggleBusquedaAfiliado();
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplyed = [];
      //chequea si es editar
      if (this.itemImporteFijo != null && this.grupoSelected.length === 0) {
        this.filtersApplyed.splice(0, 0, {
          key: "this.itemImporteFijo.afiliadoTit",
          label: "Afiliado",
          model: this.itemImporteFijo.afiliadoTit.toUpperCase()
        });
      }
      //chequea si ya selecciono un afiliado en el data table
      if (this.grupoSelected.length > 0) {
        this.filtersApplyed.splice(0, 0, {
          key: "grupoSelected",
          label: "Afiliado",
          model: this.grupoSelected[0].value.toUpperCase()
        });
        // aca chequea si todavia no selecciono afiliado pero escondio el modal de los filtros
      } else {
        if (this.afiliadoSearchDocumento) {
          this.filtersApplyed.splice(0, 1, {
            key: "dni",
            label: "N° de documento",
            model: this.afiliadoSearchDocumento
          });
        }
        if (this.afiliadoSearchNombre) {
          this.filtersApplyed.splice(1, 1, {
            key: "nombre",
            label: "Nombre",
            model: this.afiliadoSearchNombre.toUpperCase()
          });
        }
        if (this.afiliadoSearchApellido) {
          this.filtersApplyed.splice(2, 1, {
            key: "apellido",
            label: "Apellido",
            model: this.afiliadoSearchApellido.toUpperCase()
          });
        }
      }
    },
    changeSearchAfi(campoModified) {
      if (campoModified === null) {
        this.showDataTable = false;
        this.gruposFamiliaresResult = [];
        this.grupoSelected = [];
        this.nroGrupo = null;
      }
    },
    toggleBusquedaAfiliado() {
      if (!this.busquedaAfiliado) {
        this.customizeFiltersApplied();
        this.toggleBusquedaTooltip = "Ocultar filtros";
        this.busquedaIcon = "mdi-menu-up";
      } else {
        this.customizeFiltersApplied();
        this.toggleBusquedaTooltip = "Mostrar filtros";
        this.busquedaIcon = "mdi-menu-down";
      }
      this.busquedaAfiliado = !this.busquedaAfiliado;
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
