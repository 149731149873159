<template>
  <v-tooltip right>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" size="24" v-on="on" @click="volver">
        {{ volverIcon }}
      </v-icon>
    </template>
    <span>Atrás</span>
  </v-tooltip>
</template>

<script>
import enums from "@/utils/enums/index.js";

export default {
  props: {
    routeToGo: {
      type: String,
      required: false,
    },
    tabId: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    volverIcon: enums.icons.BACK,
  }),
  methods: {
    volver() {
      this.routeToGo
        ? this.$router.push({ name: this.routeToGo, params: { id: this.tabId } })
        : this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>