<template>
  <v-dialog
    v-model="openDelete"
    :max-width="maxWidth"
    @keydown.esc="close"
    persistent
  >
    <v-card>
      <v-card-title class="headline primary--text"
        ><span v-html="titleProp ? titleProp : title"></span
      ></v-card-title>
      <v-card-text v-if="textDelete" class="text-left">{{
        textDelete
      }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close" :disabled="isLoading">{{ textClose }}</v-btn>
        <v-btn
          text
          color="primary"
          dark
          @click="confirm"
          :loading="isLoading"
          >{{ confirmButtonText }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import enums from "@/utils/enums/index.js";

export default {
  name: "DeleteDialog",
  props: {
    textDelete: { type: String, default: null },
    openDelete: { type: Boolean, required: true },
    titleProp: { type: String, required: false, default: null },
    maxWidth: { type: String, required: false, default: "20%" },
    confirmButtonText: {
      type: String,
      required: false,
      default: enums.buttons.ELIMINAR
    },
    isLoading: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      dialogDelete: false,
      title: enums.titles.DIALOG_ELIMINAR,
      textClose: enums.buttons.CANCELAR,
      textConfirm: enums.buttons.ELIMINAR,
      textAnular: enums.buttons.ANULAR
    };
  },
  methods: {
    close() {
      this.dialogDelete = false;
      this.$emit("update:openDelete", false);
    },
    confirm() {
      this.$emit("onDeleteItem");
    }
  }
};
</script>
